import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_business_units } from './business_units_store'
import { use_calendar_employees } from './calendar_employees_store'
import { use_smcb_gym } from './smcb_gym_store'
import { use_work_categories } from './work_categories_store'

function parse_task_group(task_group) {
  return Object.freeze(task_group)
}

export const use_task_groups = defineStore('task_groups_store', {
  state: () => ({
    all: [],
    task_group_employees: {},
    task_groups_task_count: {},
    task_groups_task_count_loaded: false,
  }),

  actions: {
    async create_task_group(task_group) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}`, task_group)
      this.all = [...this.all, parse_task_group(data)]
      return data
    },

    async delete_task_group(task_group) {
      await Vue.smcb_axios.delete(`${this.base_url}/${task_group.id}`)

      this.all = this.all.filter(t => t.id !== task_group.id)
    },

    write_task_group(task_group) {
      const index = this.all.findIndex(t => t.id === task_group.id)
      Vue.set(this.all, index, parse_task_group(task_group))
    },

    write_task_group_employees({ task_group_id, employee_ids }) {
      Vue.set(this.task_group_employees, task_group_id, employee_ids)
    },

    update_task_groups_employees_ids(task_groups_employees) {
      for (const [task_group_id, employee_ids] of Object.entries(task_groups_employees)) {
        const task_group = this.all.find(tg => tg.id === Number(task_group_id))
        task_group.employee_ids = employee_ids
        this.write_task_group(task_group)
        this.write_task_group_employees({ task_group_id, employee_ids })
      }
    },

    async update_task_group(task_group) {
      task_group.reserved_spots = task_group.reserved_spots || 0
      const { data } = await Vue.smcb_axios.put(`${this.base_url}/${task_group.id}`, task_group)
      this.write_task_group(data)
    },

    async update_task_groups_employees(task_groups) {
      const payload = Object.fromEntries(task_groups.map(({ task_group_id, employee_id, add }) => [task_group_id, [employee_id, add]]))
      const { data } = await Vue.smcb_axios.put(`${use_smcb_gym().base_url}/task_groups_employees`, { task_groups_employees: payload })
      this.update_task_groups_employees_ids(data)

      await Promise.all([this.load_all_task_group_employees, use_work_categories().load_all_work_category_employees])
    },

    async load_all_task_group_employees() {
      const url = `${use_smcb_gym().base_url}/task_group_employees`
      const { data } = await Vue.smcb_axios.get(url)

      for (let [task_group_id, employee_ids] of Object.entries(data)) {
        this.write_task_group_employees({ task_group_id, employee_ids })
      }
    },

    async fetch_task_group_employees(task_group_id) {
      const { data } = await Vue.smcb_axios.get(this.employees_url(task_group_id))
      const employee_ids = data.map(e => e.id)
      this.write_task_group_employees({ task_group_id, employee_ids })
    },

    async update_task_group_employees({ task_group_id, employee_ids }) {
      const { data } = await Vue.smcb_axios.patch(this.employees_url(task_group_id), { ids: employee_ids })
      this.write_task_group_employees({ task_group_id, employee_ids: data.map(e => e.id) })
    },

    async load_task_groups_task_count_in_business_unit(business_unit_id) {
      const { data } = await Vue.smcb_axios.get(`${use_business_units().base_url}/${business_unit_id}/task_groups_task_count`)
      for (const [task_group_id, count] of Object.entries(data)) {
        Vue.set(this.task_groups_task_count, task_group_id, count)
      }
      this.task_groups_task_count_loaded = true
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/taskgroups`,
    active: state => state.all.filter(tg => !tg.archived_at),
    courses: state => state.all.filter(tg => !tg.archived_at && tg.is_course === true),

    employees_url: () =>
      function (task_group_id) {
        return `${this.base_url}/${task_group_id}/employees`
      },

    employees_for_task_group: state => task_group_id => {
      const employees = {}

      if (task_group_id in state.task_group_employees) {
        state.task_group_employees[task_group_id].forEach(id => {
          employees[id] = use_calendar_employees().from_id(id)
        })
      }

      return employees
    },

    task_group_from_id: state => id => state.all.find(t => t.id === id),

    is_qualified_employee_for_task_group: state =>
      function (task_group_id, employee_id) {
        const tg_employees = state.task_group_employees[task_group_id]
        if (tg_employees) return tg_employees.includes(employee_id)
        const tg = this.task_group_from_id(task_group_id)
        if (tg) return tg.employee_ids.includes(employee_id)
        return false
      },

    total_tasks_count: state => Vue.$vl_utils.array_sum(Object.values(state.task_groups_task_count)),

    all_task_groups_from_work_category_id: state => wc_id => state.all.filter(t => t.work_category_id === wc_id),

    active_task_groups_from_work_category_id: () =>
      function (wc_id) {
        return this.active.filter(t => t.work_category_id === wc_id)
      },

    task_groups_per_work_category() {
      return Object.fromEntries(use_work_categories().active.map(wc => [wc.id, this.active_task_groups_from_work_category_id(wc.id)]))
    },
  },
})
