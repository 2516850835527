import { defineStore } from 'pinia'

export const use_calendar_setup = defineStore('calendar_setup', {
  state: () => ({
    new_work_category: false,
    work_category_to_edit: {},
    new_business_unit: false,
    business_unit_to_edit: {},
    new_task_group: false,
    task_group_to_edit: {},
    local_storage_key_active_business_unit_id: 'calendar_setup_active_business_unit_id',
  }),

  actions: {
    add_work_category(work_category) {
      this.new_work_category = true
      this.work_category_to_edit = work_category
    },
    edit_work_category(work_category) {
      this.new_work_category = false
      this.work_category_to_edit = work_category
    },
    add_business_unit() {
      this.new_business_unit = true
      this.business_unit_to_edit = {}
    },
    edit_business_unit(business_unit) {
      this.new_business_unit = false
      this.business_unit_to_edit = business_unit
    },
    add_task_group(task_group) {
      this.new_task_group = true
      this.task_group_to_edit = {
        show_on_calendar: true,
        unlocks_private_checkins: false,
        ...task_group,
      }
    },
    edit_task_group(task_group) {
      this.new_task_group = false
      this.task_group_to_edit = task_group
    },
  },
})
