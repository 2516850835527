import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_smcb_gym } from './smcb_gym_store'
import { use_tasks } from './tasks_store'

const parse_recurring_task = rtask => {
  return {
    ...rtask,
    start_date: DateTime.fromISO(rtask.start_date),
    end_date: rtask.end_date && DateTime.fromISO(rtask.end_date),
  }
}

export const use_recurring_tasks = defineStore('recurring_tasks_store', {
  state: () => ({
    all: [],
  }),

  actions: {
    add_recurring_tasks(recurring_tasks) {
      let sum = [...this.all, ...recurring_tasks.map(rtask => parse_recurring_task(rtask))]
      this.all = sum.filter((rt_a, index) => sum.findIndex(rt_b => rt_b.id === rt_a.id) === index)
    },

    add_update_recurring_task(rtask) {
      if (this.all.find(t => t.id === rtask.id)) {
        this.all = this.all.map(t => (t.id !== rtask.id ? t : parse_recurring_task(rtask)))
      } else {
        this.all = [...this.all, parse_recurring_task(rtask)]
      }
    },

    async create(payload) {
      const url = `${this.recurring_task_api}`
      const { data } = await Vue.smcb_axios.post(url, payload)
      this.add_update_recurring_task(data.recurringTask)
      use_tasks().add_tasks(data.tasks)

      return data.tasks.map(t => t.id)
    },

    async update_recurring_tasks({ recurring_task, mode, invitations }) {
      const url = `${this.recurring_task_api}/${recurring_task.task.recurring_task_id}`
      const { data } = await Vue.smcb_axios.put(
        url,
        { ...recurring_task, invitations },
        {
          params: {
            mode,
          },
        }
      )

      data.tasks.forEach(t => use_tasks().add_update_task(t))
      this.add_update_recurring_task(data.recurringTask)

      return data.tasks.map(t => t.id)
    },

    async update_recurring_tasks_dates_from_task({ task, mode, public_dates_mode }) {
      const url = `${this.recurring_task_api}/${task.recurring_task_id}/dates`
      const { data } = await Vue.smcb_axios.patch(
        url,
        { task },
        {
          params: {
            mode,
            public_dates_mode,
          },
        }
      )

      data.tasks.forEach(t => use_tasks().add_update_task(t))
      this.add_update_recurring_task(data.recurringTask)

      return data.tasks.map(t => t.id)
    },

    async delete_recurring_task({ task, mode }) {
      const url = `${this.recurring_task_api}/${task.recurring_task_id}`
      const { data } = await Vue.smcb_axios.delete(url, {
        params: {
          mode,
          task_id: task.id,
        },
      })

      use_tasks().remove_many_tasks(data)

      const associated_tasks = this.associated_recurring_tasks(task.recurring_task_id)

      if (associated_tasks.length === 0) {
        this.all = this.all.filter(t => t.id !== task.recurring_task_id)
      }
    },
  },

  getters: {
    recurring_task_api: () => `${use_smcb_gym().base_url}/recurringtasks`,
    recurring_task_from_id: state => id => state.all.find(t => t.id === id),
    associated_recurring_tasks: () => id => use_tasks().all.filter(t => t.recurring_task_id === id),
  },
})
