import { defineStore } from 'pinia'

export const use_pricings = defineStore('pricings', {
  state: () => ({
    editing: false,
    pricing_to_edit: {},
  }),

  actions: {
    edit(pricing) {
      this.editing = true
      this.pricing_to_edit = pricing
    },

    cancel_edit() {
      this.editing = false
      this.pricing_to_edit = {}
    },
  },
})
